import * as _ from 'lodash'
import { FlightChart } from './flight_chart'
import { FlightChartOptions, Disc, Bag, PowerLevel, MeasurementUnit } from '../../models'
import { FlightPathOrientation } from './helper'

const TAG_NAME = 'x-flight-chart'

type ObservedAttribute =
   | 'selected-disc'
   | 'discs'
   | 'thumbnail-view'
   | 'power-level'
   | 'measurement-unit'
   | 'default-flight-path-orientation'
   | 'show-labels'

class FlightChartComponent extends HTMLElement {
   flight_chart: FlightChart = null
   animation_id = null
   discs_cache: Disc[] = []
   is_thumnbail_view: boolean = false
   power_level: PowerLevel = null
   measurement_unit: MeasurementUnit = null
   flight_path_orientation: FlightPathOrientation = null
   show_labels: boolean = null

   connectedCallback() {
      this.ensureChartCreated()
      this.discs_cache = JSON.parse(this.attributes.getNamedItem('discs')?.value)
      this.is_thumnbail_view = JSON.parse(this.attributes.getNamedItem('thumbnail-view')?.value ?? 'false')
      this.power_level = _.isEmpty(this.attributes.getNamedItem('power-level')?.value)
         ? null
         : (this.attributes.getNamedItem('power-level')?.value as PowerLevel)
      this.measurement_unit = _.isEmpty(this.attributes.getNamedItem('measurement-unit')?.value)
         ? null
         : (this.attributes.getNamedItem('measurement-unit')?.value as MeasurementUnit)
      this.flight_path_orientation = _.isEmpty(this.attributes.getNamedItem('default-flight-path-orientation')?.value)
         ? null
         : (this.attributes.getNamedItem('default-flight-path-orientation')?.value as FlightPathOrientation)
      this.show_labels = _.isEmpty(this.attributes.getNamedItem('show-labels')?.value)
         ? null
         : !!JSON.parse(this.attributes.getNamedItem('show-labels')?.value)

      this.flight_chart.initialize({
         discs: this.discs_cache,
         is_thumbnail_view: this.is_thumnbail_view,
         power_level: this.power_level,
         measurement_unit: this.measurement_unit,
         flight_path_orientation: this.flight_path_orientation,
         show_labels: this.show_labels,
      })
   }

   set discs(new_discs: Disc[]) {
      if (_.isNil(this.parentNode)) {
         return
      }

      if (_.isEqual(this.discs_cache, new_discs)) {
         return
      }

      this.discs_cache = new_discs

      this.ensureChartCreated()
      this.flight_chart.initialize({
         discs: this.discs_cache,
         is_thumbnail_view: this.is_thumnbail_view,
         power_level: this.power_level,
         measurement_unit: this.measurement_unit,
         flight_path_orientation: this.flight_path_orientation,
         show_labels: this.show_labels,
      })
   }

   static get observedAttributes() {
      return ['selected-disc', 'discs', 'thumbnail-view', 'power-level', 'measurement-unit', 'default-flight-path-orientation', 'show-labels']
   }

   attributeChangedCallback(name: ObservedAttribute, old_value, new_value) {
      if (_.isNil(this.parentNode)) {
         return
      }

      this.ensureChartCreated()

      switch (name) {
         case 'selected-disc':
            this.flight_chart.selectedDisc(new_value)
            break

         case 'thumbnail-view':
            this.flight_chart.setThumbnailView(JSON.parse(new_value))
            break

         case 'power-level':
            this.flight_chart.setPowerLevel(new_value)
            break

         case 'measurement-unit':
            this.flight_chart.setMeasurementUnit(new_value)
            break

         case 'default-flight-path-orientation':
            this.flight_chart.setFlightPathOrientation(new_value)
            break

         case 'show-labels':
            this.flight_chart.setShowLabels(new_value)
            break

         case 'discs':
            {
               const new_discs = JSON.parse(new_value)

               if (_.isNil(this.parentNode)) {
                  return
               }

               if (_.isEqual(this.discs_cache, new_discs)) {
                  return
               }

               this.discs_cache = new_discs

               this.ensureChartCreated()
               this.flight_chart.initialize({
                  discs: this.discs_cache,
                  is_thumbnail_view: this.is_thumnbail_view,
                  power_level: this.power_level,
                  measurement_unit: this.measurement_unit,
                  flight_path_orientation: this.flight_path_orientation,
                  show_labels: this.show_labels,
               })
            }
            break

         default:
            const _exhaustive_check: never = name
            throw new TypeError(`The value ${_exhaustive_check} is not assignable to type ObservedAttribute`)
      }
   }

   disconnectedCallback() {
      this.flight_chart.destroy()
   }

   ensureChartCreated() {
      if (!_.isNil(this.flight_chart)) {
         return
      }

      const options: FlightChartOptions = {
         onDiscSelected: getOnDiscSelected(this),
         onEscape: getOnEscape(this),
      }

      this.flight_chart = new FlightChart(this, options)
   }
}

export function registerFlightChartComponent() {
   return customElements.define(TAG_NAME, FlightChartComponent)
}

function getOnDiscSelected(base_element) {
   return function (disc) {
      const event = new CustomEvent('disc-selected', {
         detail: { disc: disc },
         bubbles: true,
      })

      base_element.dispatchEvent(event)
   }
}

function getOnEscape(base_element) {
   return function () {
      const event = new CustomEvent('escape', {
         detail: null,
         bubbles: true,
      })

      base_element.dispatchEvent(event)
   }
}
