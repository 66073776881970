import * as d3 from 'd3'
import * as _ from 'lodash'
import * as M from '../../models'
import {
   drawFlightPath as calculateFlightPath,
   modifyArmspeedByDiscWeight,
   FlightPathOrientation,
   PathData,
   getSVGString,
   svgString2Image,
} from './helper'
import tippy from 'tippy.js'
import { saveAs } from 'file-saver'
import { TinyColor } from '@ctrl/tinycolor'

const margin = {
   top: 30,
   padding_top: 5,
   padding_left: 10,
   right: 0,
   bottom: 30,
   left: 50,
   flight_path_top: 20,
   flight_path_left: 30,
   flight_path_right: 20,
}
const KEY_CODE_ESCAPE = 27
const FLIGHT_ANIMATION_DURATION = 2500

interface FlightPathToRender {
   disc: M.Disc
   path_data: PathData
}

function power_level_mapping(power_level: M.PowerLevel) {
   switch (power_level) {
      case M.PowerLevel.Novice: {
         return 16
      }
      case M.PowerLevel.Recreational: {
         return 20
      }
      case M.PowerLevel.Intermediate: {
         return 24
      }
      case M.PowerLevel.Advanced: {
         return 28
      }
      case M.PowerLevel.Pro: {
         return 32
      }
      case M.PowerLevel.ElitePro: {
         return 36
      }
      default: {
         return 28
      }
   }
}

function feet_to_meters(feet: number) {
   return feet * 0.3048
}

export class FlightChart {
   onDiscSelected: (disc: M.Disc) => void
   onEscape: () => void

   should_animate: boolean = true
   discs: M.Disc[]
   show_labels: boolean = true
   container: HTMLElement

   page_loading: boolean = true

   data_viewport_height: number = null
   data_viewport_width: number = null
   x_scale: d3.ScaleLinear<number, number>
   y_scale: d3.ScaleLinear<number, number>
   y_axis_scale: d3.ScaleLinear<number, number>

   extents_padding: number = 7
   selected_disc: M.Disc = null
   hovered_disc: M.Disc = null

   svg: d3.Selection<any, any, any, any>

   layers: {
      [k: string]: d3.Selection<any, any, any, any>
   }

   flight_path_orientation: FlightPathOrientation
   measurement_unit: M.MeasurementUnit
   power_level: M.PowerLevel
   release_angle: number = 0
   wind_speed: number = 0
   flight_paths: FlightPathToRender[] = []
   total_min_x: number
   total_max_x: number
   total_max_y: number
   is_thumbnail_view: boolean

   constructor(container: HTMLElement, options: M.FlightChartOptions) {
      this.onDiscSelected = options.onDiscSelected || this.onDiscSelected
      this.onEscape = options.onEscape || this.onEscape
      this.discs = null
      this.container = container

      d3.select('body').on('keydown', () => {
         this.handleKeyDown(d3.event as KeyboardEvent)
      })
   }

   initialize(options: {
      discs: M.Disc[]
      power_level: M.PowerLevel
      measurement_unit: M.MeasurementUnit
      is_thumbnail_view: boolean
      flight_path_orientation: FlightPathOrientation
      show_labels: boolean
   }) {
      this.page_loading = false
      this.discs = options.discs ?? []
      this.is_thumbnail_view = options.is_thumbnail_view ?? false
      this.power_level = options.power_level ?? (options.is_thumbnail_view ? M.PowerLevel.Intermediate : M.PowerLevel.ElitePro)
      this.measurement_unit = options.measurement_unit ?? M.MeasurementUnit.Feet
      this.flight_path_orientation = options.flight_path_orientation ?? FlightPathOrientation.RHBH
      this.show_labels = options.show_labels ?? false

      this.renderAll(true)
   }

   renderAll(full_redraw: boolean = false, should_animate: boolean = null) {
      if (full_redraw) {
         this.calculateFlightPaths()

         if (_.isEmpty(this.flight_paths)) {
            return
         }

         this.setupSVG()
         this.renderYAxis()
      }

      this.renderFlightPaths({ should_animate: should_animate ?? this.should_animate })
   }

   selectedDisc(disc: M.Disc) {
      this.selected_disc = disc

      this.renderFlightPaths({ should_animate: false })
   }

   destroy() {}

   private handleKeyDown(key_event: KeyboardEvent) {
      // Navigation
      if (/^(input|textarea|select)$/i.test((key_event.target as Node).nodeName)) {
         return
      }

      if (!_.isNil(this.selected_disc)) {
         if (key_event.keyCode === KEY_CODE_ESCAPE) {
            this.onEscape()
         }
      }
   }

   normalize_height(height_value_in_feet: number) {
      return this.measurement_unit === M.MeasurementUnit.Feet ? height_value_in_feet : feet_to_meters(height_value_in_feet)
   }

   private setupSVG() {
      if (!_.isNil(this.svg)) {
         this.container.innerHTML = ''
      }
      const bagbuildercom_text_width_buffer = 175
      this.data_viewport_width = _.max([
         250,
         margin.flight_path_left +
            margin.flight_path_right +
            Math.abs(this.total_min_x) +
            Math.abs(this.total_max_x) +
            bagbuildercom_text_width_buffer,
      ])
      const at_least_this_height = this.is_thumbnail_view ? 450 : 350
      this.data_viewport_height = _.max([at_least_this_height, margin.flight_path_top + this.total_max_y])

      this.x_scale = d3
         .scaleLinear()
         .domain([-(this.data_viewport_width / 2), this.data_viewport_width / 2])
         .range([0, this.data_viewport_width])
      this.y_scale = d3
         .scaleLinear()
         .domain([0, this.total_max_y + margin.flight_path_top])
         .range([this.data_viewport_height, 0])
      this.y_axis_scale = d3
         .scaleLinear()
         .domain([0, this.normalize_height(this.total_max_y) + this.normalize_height(margin.flight_path_top)])
         .range([this.data_viewport_height, 0])

      const offsets = this.calculateOffsets()

      this.svg = d3
         .select(this.container)
         .append('svg')
         .attr('viewBox', `0 0 ${offsets.total_width} ${offsets.total_height}`)
         .classed('max-h-screen', true)
         .classed('bg-white', true)
         .style(
            'font-family',
            `Inter var, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
         )

      const layer_requests = [
         { name: 'y_axis', root: this.svg, scrollable: false, x_offset: margin.padding_left, y_offset: offsets.data_viewport + margin.padding_top },
         {
            name: 'flight_paths',
            root: this.svg,
            scrollable: false,
            x_offset: margin.padding_left,
            y_offset: offsets.data_viewport + margin.padding_top,
         },
         { name: 'labels', root: this.svg, scrollable: false, x_offset: margin.padding_left, y_offset: margin.padding_top },
      ]

      const gfx_layers = _.map(layer_requests, layer => {
         const root_node = layer.root
         return {
            name: layer.name,
            layer: root_node.append('g').classed(layer.name, true).attr('transform', `translate(${layer.x_offset}, ${layer.y_offset})`),
            x_offset: layer.x_offset,
            y_offset: layer.y_offset,
            scrollable: layer.scrollable,
         }
      })

      this.layers = _.fromPairs(_.map(gfx_layers, x => [x.name, x.layer]))

      this.layers.flight_paths.append('g').attr('class', 'flight-paths')

      // Prevent d3 from capturing of the scroll wheel
      // We want the user to be able to scroll down the page regardless of where
      // their cursor is.
      this.svg
         .on('mousewheel.zoom', null)
         .on('DOMMouseScroll.zoom', null) // disables older versions of Firefox
         .on('wheel.zoom', null) // disables newer versions of Firefox

      // Set-up the export button (rendered outside)
      d3.select('#save-image-button').on('click', () => {
         var svgString = getSVGString(this.svg.node(), 2 * this.data_viewport_width, 2 * this.data_viewport_height)

         svgString2Image(svgString, 2 * this.data_viewport_width, 2 * this.data_viewport_height, 'png', save) // passes Blob and filesize String to the callback

         function save(dataBlob, filesize) {
            saveAs(dataBlob, `discgolfbagbuilder-flight-chart.png`)
         }
      })
   }

   private renderYAxis() {
      const yAxis = g =>
         g
            .call(
               d3
                  .axisRight(this.y_axis_scale)
                  .tickSize(this.data_viewport_width - margin.left - margin.right)
                  .tickFormat(d => (this.measurement_unit === M.MeasurementUnit.Feet ? `${d} ft` : `${d} m`))
            )
            .call(g => g.selectAll('.tick:not(:first-of-type) line').attr('stroke-opacity', 0.5).attr('stroke-dasharray', '2,2'))
            .call(g => g.selectAll('.tick text').attr('x', -margin.left).attr('dy', 2))

      let y_axis_container = this.layers.y_axis

      if (!y_axis_container.empty()) {
         y_axis_container.html('')
      }

      if (this.is_thumbnail_view) {
         return
      }

      y_axis_container = this.layers.y_axis
         .append('g')
         .classed('text-gray-500', true)
         .classed('text-xs', true)
         .call(yAxis)
         .attr('transform', `translate(${margin.left}, 0)`)

      y_axis_container.selectAll('.domain').attr('opacity', 0)
   }

   private calculateFlightPaths() {
      // TODO: only recalculate if params changed.
      this.flight_paths = _.compact(
         this.discs.map(disc => {
            if (_.isNil(disc.max_distance) || _.isNil(disc.hst) || _.isNil(disc.lsf)) {
               return null
            }

            return {
               disc: disc,
               path_data: calculateFlightPath({
                  max_distance: disc.max_distance,
                  hst: disc.hst,
                  lsf: disc.lsf,
                  angle: this.release_angle, // -100 to 100
                  power_level: modifyArmspeedByDiscWeight(disc.weight ?? 175, power_level_mapping(this.power_level)), // 0 to 48
                  flight_path_orientation: disc.throwing_handedness ?? this.flight_path_orientation,
                  disc_wear: disc.condition || 10, // 1 to 10
                  wind_speed: this.wind_speed, // -40 to 40, negative is headwind
               }),
            }
         })
      )

      if (_.isEmpty(this.flight_paths)) {
         return
      }

      this.total_min_x = _.min(this.flight_paths.map(x => x.path_data.min_x))
      this.total_max_x = _.max(this.flight_paths.map(x => x.path_data.max_x))
      this.total_max_y = _.max(this.flight_paths.map(x => x.path_data.final_lie_y))
   }

   private renderFlightPaths({ should_animate }: { should_animate: boolean }) {
      if (_.isEmpty(this.flight_paths)) {
         return
      }

      const outer_this = this
      const scales = { x: this.x_scale, y: this.y_scale }

      const my_color = d3.scaleSequential(d3.interpolateViridis).domain([1, this.discs.length])
      const stroke_opacity = this.is_thumbnail_view ? 1.0 : 0.7
      const stroke_width = this.is_thumbnail_view ? 8 : 2
      const landing_circle_radius = this.is_thumbnail_view ? 12 : 3

      const renderFlightPath = function ({ disc, path_data }: FlightPathToRender, index: number) {
         const selection = d3.select(this)

         var line = d3
            .line()
            .x(d => scales.x(d[0]))
            .y(d => scales.y(d[1]))
            .curve(d3.curveNatural)

         const color = _.isEmpty(disc.color) ? my_color(index) : disc.color

         const color_is_too_bright = new TinyColor(color).getBrightness() > 220

         const outline_path = color_is_too_bright
            ? selection
                 .append('path')
                 .attr('transform', 'translate(1, 0)')
                 .attr('stroke-width', stroke_width - 1)
                 .attr('d', line(path_data.xy_pairs))
                 .attr('stroke', '#333')
                 .attr('opacity', 0.5)
                 .attr('fill', 'none')
            : null

         const outline_path_2 = color_is_too_bright
            ? selection
                 .append('path')
                 .attr('transform', 'translate(-1, 0)')
                 .attr('stroke-width', stroke_width - 1)
                 .attr('d', line(path_data.xy_pairs))
                 .attr('stroke', '#333')
                 .attr('opacity', 0.5)
                 .attr('fill', 'none')
            : null

         const path = selection
            .append('path')
            .attr('stroke-width', stroke_width)
            .attr('d', line(path_data.xy_pairs))
            .attr('stroke', _.isEmpty(disc.color) ? my_color(index) : disc.color)
            .attr('opacity', stroke_opacity)
            .attr('fill', 'none')

         var path_total_length = path.node().getTotalLength()

         const renderLandingCircle = () => {
            selection
               .append('circle')
               .classed(`flight-path-${disc.disc_id}`, true)
               .attr('r', landing_circle_radius)
               .attr('fill', color)
               .attr('stroke', color_is_too_bright ? '#333' : null)
               .attr('stroke-width', color_is_too_bright ? '1px' : null)
               .attr('stroke-opacity', color_is_too_bright ? stroke_opacity : null)
               .attr('transform', `translate(${scales.x(path_data.final_lie_x)}, ${scales.y(path_data.final_lie_y)})`)

            if (outer_this.show_labels) {
               selection
                  .append('text')
                  .classed(`disc-label-${disc.disc_id}`, true)
                  .classed(`text-xxs text-gray-400 font-semibold`, true)
                  .attr('transform', `translate(${scales.x(path_data.final_lie_x)}, ${scales.y(path_data.final_lie_y)})`)
                  .attr('text-anchor', 'middle')
                  .attr('dy', '-1.1em')
                  .text(disc.disc_model?.name || disc.disc_model_manual)
            }
         }

         if (should_animate) {
            path
               .attr('stroke-dasharray', path_total_length + ' ' + path_total_length)
               .attr('stroke-dashoffset', path_total_length)
               .transition()
               .duration(FLIGHT_ANIMATION_DURATION)
               .ease(d3.easePoly)
               .attr('stroke-dashoffset', 0)
               .on('end', renderLandingCircle)

            if (color_is_too_bright) {
               outline_path
                  .attr('stroke-dasharray', path_total_length + ' ' + path_total_length)
                  .attr('stroke-dashoffset', path_total_length)
                  .transition()
                  .duration(FLIGHT_ANIMATION_DURATION)
                  .ease(d3.easePoly)
                  .attr('stroke-dashoffset', 0)
               outline_path_2
                  .attr('stroke-dasharray', path_total_length + ' ' + path_total_length)
                  .attr('stroke-dashoffset', path_total_length)
                  .transition()
                  .duration(FLIGHT_ANIMATION_DURATION)
                  .ease(d3.easePoly)
                  .attr('stroke-dashoffset', 0)
            }
         } else {
            renderLandingCircle()
         }
      }

      if (!this.is_thumbnail_view) {
         this.layers.labels
            .append('g')
            .attr('opacity', 0.7)
            .attr(
               'transform',
               `translate(${scales.x(-scales.x(this.total_min_x) + 50 + margin.left)}, ${scales.y(4) + margin.top - margin.padding_top})`
            )
            .append('text')
            .attr('class', 'site-attribution text-gray-400 text-sm')
            .text('discgolfbagbuilder.com')

         const header_text = this.layers.labels
            .append('g')
            .attr('opacity', 0.7)
            .attr('transform', `translate(0, ${margin.top / 2})`)
            .append('text')
            .attr('class', 'site-attribution text-gray-400 text-sm')

         header_text.append('tspan').attr('class', 'font-bold').text('Power: ')
         header_text.append('tspan').text(M.prettyPowerLevel(this.power_level))

         header_text.append('tspan').text('  ')

         header_text.append('tspan').attr('class', 'font-bold').text('Throwing: ')
         header_text.append('tspan').text(_.toUpper(this.flight_path_orientation))
      }

      const selection = this.layers.flight_paths.select('.flight-paths').selectAll('.flight-path').data(this.flight_paths)

      selection
         .enter()
         .append('g')
         .attr('class', 'flight-path')
         .attr('transform', `translate(${-scales.x(this.total_min_x) + 20 + margin.left},0)`) // position the left-most path 20px from y-axis
         .each(renderFlightPath)
         .on('mouseover', this.showTooltip)
         .on('mouseout', this.removeTooltip)
         .on('click', d => {
            // TODO: track event in ahoy : window._gaq.push(['trackEvent', 'adherence-chart', 'dose-event-click', d.period_date, null])
            this.onDiscSelected(d.disc)
         })

      selection.exit().remove()
   }

   //Show the tooltip on the hovered over slice
   private showTooltip(d: { disc: M.Disc; path_data: any }, i) {
      // var d3_element = d3.selectAll(`.flight-path-${d.disc.disc_id}`)
      // const element = d3_element.node() as Element

      const tooltipContent = () => {
         return `
        <div class="rounded-md shadow-lg">
          <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div class="px-4 py-3">
              <span class="text-md text-gray-800">${d.disc.disc_model.disc_manufacturer.common_name} ${d.disc.plastic_name ?? ''} ${
            d.disc.disc_model.name
         }</span>
            </div>
          </div>
        </div>
        `
      }

      tippy(`.flight-path-${d.disc.disc_id}`, {
         content: tooltipContent(),
         allowHTML: true,
      })
   }

   private removeTooltip(d: { disc: M.Disc; path_data: any }, i) {
      var d3_element = d3.selectAll(`.flight-path-${d.disc.disc_id}`)
      const element: any = d3_element.node() as Element

      if (element) {
         element._tippy?.destroy()
      }
   }

   private calculateOffsets() {
      const data_viewport = margin.top
      const x_axis = data_viewport + this.data_viewport_height

      return {
         data_viewport: data_viewport,
         x_axis: x_axis,
         total_height: this.data_viewport_height + margin.top + margin.bottom,
         total_width: this.data_viewport_width + margin.left + margin.right,
      }
   }

   setThumbnailView(is_thumbnail_view: boolean) {
      this.is_thumbnail_view = is_thumbnail_view

      if (!_.isEmpty(this.discs)) {
         this.renderAll(true, true)
      }
   }

   setPowerLevel(power_level: M.PowerLevel) {
      this.power_level = power_level

      if (!_.isEmpty(this.discs)) {
         this.renderAll(true, true)
      }
   }

   setMeasurementUnit(measurement_unit: M.MeasurementUnit) {
      this.measurement_unit = measurement_unit

      if (!_.isEmpty(this.discs)) {
         this.renderAll(true, true)
      }
   }

   setFlightPathOrientation(flight_path_orientation: FlightPathOrientation) {
      this.flight_path_orientation = flight_path_orientation

      if (!_.isEmpty(this.discs)) {
         this.renderAll(true, true)
      }
   }

   setShowLabels(show_labels: boolean) {
      this.show_labels = show_labels

      if (!_.isEmpty(this.discs)) {
         this.renderAll(true, false)
      }
   }
}
