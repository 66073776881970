import * as _ from 'lodash'
import { StabilityChart } from './stability_chart'
import { FlightChartOptions, Disc, SpeedDistanceAxisChoice, StabilityAxisChoice } from '../../models'
import { MeasurementUnit } from 'models/app'

const TAG_NAME = 'x-stability-chart'

type ObservedAttribute = 'selected-disc' | 'discs' | 'speed-distance-axis-choice' | 'stability-axis-choice' | 'measurement-unit' | 'show-labels'

class StabilityChartComponent extends HTMLElement {
   stability_chart: StabilityChart = null
   animation_id = null
   discs_cache: Disc[] = []
   speed_distance_axis_choice: SpeedDistanceAxisChoice = null
   stability_axis_choice: StabilityAxisChoice = null
   measurement_unit: MeasurementUnit = null
   show_labels: boolean = null

   connectedCallback() {
      this.ensureChartCreated()
      this.discs_cache = JSON.parse(this.attributes.getNamedItem('discs')?.value)
      this.speed_distance_axis_choice = _.isEmpty(this.attributes.getNamedItem('speed-distance-axis-choice')?.value)
         ? null
         : (this.attributes.getNamedItem('speed-distance-axis-choice')?.value as SpeedDistanceAxisChoice)
      this.stability_axis_choice = _.isEmpty(this.attributes.getNamedItem('stability-axis-choice')?.value)
         ? null
         : (this.attributes.getNamedItem('stability-axis-choice')?.value as StabilityAxisChoice)
      this.measurement_unit = _.isEmpty(this.attributes.getNamedItem('measurement-unit')?.value)
         ? null
         : (this.attributes.getNamedItem('measurement-unit')?.value as MeasurementUnit)
      this.show_labels = _.isEmpty(this.attributes.getNamedItem('show-labels')?.value)
         ? null
         : !!JSON.parse(this.attributes.getNamedItem('show-labels')?.value)

      this.stability_chart.initialize({
         discs: this.discs_cache,
         speed_distance_axis_choice: this.speed_distance_axis_choice,
         stability_axis_choice: this.stability_axis_choice,
         measurement_unit: this.measurement_unit,
         show_labels: this.show_labels,
      })
   }

   set discs(new_discs: Disc[]) {
      if (_.isNil(this.parentNode)) {
         return
      }

      if (_.isEqual(this.discs_cache, new_discs)) {
         return
      }

      this.discs_cache = new_discs

      this.ensureChartCreated()
      this.stability_chart.initialize({
         discs: this.discs_cache,
         speed_distance_axis_choice: this.speed_distance_axis_choice,
         stability_axis_choice: this.stability_axis_choice,
         measurement_unit: this.measurement_unit,
         show_labels: this.show_labels,
      })
   }

   static get observedAttributes() {
      return ['selected-disc', 'discs', 'speed-distance-axis-choice', 'stability-axis-choice', 'measurement-unit', 'show-labels']
   }

   attributeChangedCallback(name: ObservedAttribute, old_value, new_value) {
      if (_.isNil(this.parentNode)) {
         return
      }

      this.ensureChartCreated()

      switch (name) {
         case 'selected-disc':
            this.stability_chart.selectedDisc(new_value)
            break

         case 'measurement-unit':
            this.stability_chart.setMeasurementUnit(new_value)
            break

         case 'speed-distance-axis-choice':
            this.stability_chart.setSpeedDistanceAxisChoice(new_value)
            break

         case 'stability-axis-choice':
            this.stability_chart.setStabilityAxisChoice(new_value)
            break

         case 'show-labels':
            this.stability_chart.setShowLabels(new_value)
            break

         case 'discs':
            {
               const new_discs = JSON.parse(new_value)

               if (_.isNil(this.parentNode)) {
                  return
               }

               if (_.isEqual(this.discs_cache, new_discs)) {
                  return
               }

               this.discs_cache = new_discs

               this.ensureChartCreated()
               this.stability_chart.initialize({
                  discs: this.discs_cache,
                  speed_distance_axis_choice: this.speed_distance_axis_choice,
                  stability_axis_choice: this.stability_axis_choice,
                  measurement_unit: this.measurement_unit,
                  show_labels: this.show_labels,
               })
            }
            break

         default:
            const _exhaustive_check: never = name
            throw new TypeError(`The value ${_exhaustive_check} is not assignable to type ObservedAttribute`)
      }
   }

   disconnectedCallback() {
      this.stability_chart.destroy()
   }

   ensureChartCreated() {
      if (!_.isNil(this.stability_chart)) {
         return
      }

      const options: FlightChartOptions = {
         onDiscSelected: getOnDiscSelected(this),
         onEscape: getOnEscape(this),
      }

      this.stability_chart = new StabilityChart(this, options)
   }
}

export function registerStabilityChartComponent() {
   return customElements.define(TAG_NAME, StabilityChartComponent)
}

function getOnDiscSelected(base_element) {
   return function (disc) {
      const event = new CustomEvent('disc-selected', {
         detail: { disc: disc },
         bubbles: true,
      })

      base_element.dispatchEvent(event)
   }
}

function getOnEscape(base_element) {
   return function () {
      const event = new CustomEvent('escape', {
         detail: null,
         bubbles: true,
      })

      base_element.dispatchEvent(event)
   }
}
