import * as d3 from 'd3'
import * as _ from 'lodash'
import * as M from '../../models'
import tippy from 'tippy.js'
import { saveAs } from 'file-saver'
import { TinyColor } from '@ctrl/tinycolor'
import { getSVGString, svgString2Image } from 'components/flight-chart/helper'

const margin = {
   top: 40,
   padding_top: 5,
   padding_left: 10,
   right: 25,
   bottom: 30,
   left: 50,
   flight_path_top: 20,
   flight_path_left: 30,
   flight_path_right: 20,
}

const KEY_CODE_ESCAPE = 27

function feet_to_meters(feet: number) {
   return feet * 0.3048
}

export class StabilityChart {
   onDiscSelected: (disc: M.Disc) => void
   onEscape: () => void

   should_animate: boolean = true
   discs: M.Disc[]
   show_labels: boolean = true
   container: HTMLElement

   page_loading: boolean = true

   data_viewport_height: number = null
   data_viewport_width: number = null
   offsets: {
      data_viewport: number
      x_axis: number
      total_height: number
      total_width: number
   } = null
   x_scale: d3.ScaleLinear<number, number>
   y_scale: d3.ScaleLinear<number, number>

   extents_padding: number = 7
   selected_disc: M.Disc = null
   hovered_disc: M.Disc = null

   svg: d3.Selection<any, any, any, any>

   layers: {
      [k: string]: d3.Selection<any, any, any, any>
   }

   measurement_unit: M.MeasurementUnit
   speed_distance_axis_choice: M.SpeedDistanceAxisChoice
   stability_axis_choice: M.StabilityAxisChoice

   default_color_scale: d3.ScaleSequential<string>
   dot_radius: number = 8
   stroke_opacity: number = 0.7

   constructor(container: HTMLElement, options: M.FlightChartOptions) {
      this.onDiscSelected = options.onDiscSelected || this.onDiscSelected
      this.onEscape = options.onEscape || this.onEscape
      this.discs = null
      this.container = container

      d3.select('body').on('keydown', () => {
         this.handleKeyDown(d3.event as KeyboardEvent)
      })
   }

   initialize(options: {
      discs: M.Disc[]
      measurement_unit: M.MeasurementUnit
      speed_distance_axis_choice: M.SpeedDistanceAxisChoice
      stability_axis_choice: M.StabilityAxisChoice
      show_labels: boolean
   }) {
      this.page_loading = false
      this.discs = options.discs ?? []
      this.measurement_unit = options.measurement_unit ?? M.MeasurementUnit.Feet
      this.speed_distance_axis_choice = options.speed_distance_axis_choice ?? M.SpeedDistanceAxisChoice.RimWidthRatio
      this.stability_axis_choice = options.stability_axis_choice ?? M.StabilityAxisChoice.TurnFade
      this.show_labels = options.show_labels ?? false

      this.renderAll(true)
   }

   renderAll(full_redraw: boolean = false) {
      if (full_redraw) {
         if (_.isEmpty(this.discs)) {
            return
         }

         this.default_color_scale = d3.scaleSequential(d3.interpolateViridis).domain([1, this.discs.length])

         this.setupSVG()
         this.renderXAxis()
         this.renderYAxis()
      }

      this.renderDiscs()
   }

   selectedDisc(disc: M.Disc) {
      this.selected_disc = disc

      this.renderDiscs()
   }

   destroy() {}

   private handleKeyDown(key_event: KeyboardEvent) {
      // Navigation
      if (/^(input|textarea|select)$/i.test((key_event.target as Node).nodeName)) {
         return
      }

      if (!_.isNil(this.selected_disc)) {
         if (key_event.keyCode === KEY_CODE_ESCAPE) {
            this.onEscape()
         }
      }
   }

   // TODO: use this for max distance y-axis
   normalize_height(height_value_in_feet: number) {
      return this.measurement_unit === M.MeasurementUnit.Feet ? height_value_in_feet : feet_to_meters(height_value_in_feet)
   }

   private getXAttribute(disc: M.Disc): number {
      switch (this.stability_axis_choice) {
         case M.StabilityAxisChoice.TurnFade: {
            if (_.isNil(disc.fade) || _.isNil(disc.turn)) {
               return 0
            }

            return disc.fade + disc.turn
         }

         case M.StabilityAxisChoice.HstLsf: {
            if (_.isNil(disc.hst) || _.isNil(disc.lsf)) {
               return 0
            }

            return disc.hst + disc.lsf
         }

         default:
            throw new Error('Invalid Stability Choice')
      }
   }

   private getXAttributeBands(disc: M.Disc): { left: number; right: number } {
      // TODO: Null handling / defaults.

      switch (this.stability_axis_choice) {
         case M.StabilityAxisChoice.TurnFade: {
            return {
               left: disc.fade,
               right: disc.turn,
            }
         }

         case M.StabilityAxisChoice.HstLsf: {
            return {
               left: disc.lsf,
               right: disc.hst,
            }
         }

         default:
            throw new Error('Invalid Stability Choice')
      }
   }

   private getYAttribute(disc: M.Disc): number {
      switch (this.speed_distance_axis_choice) {
         case M.SpeedDistanceAxisChoice.Speed: {
            if (_.isNil(disc.disc_model?.inbounds_flight_datum)) {
               return 0.5
            }

            return disc.speed
         }

         case M.SpeedDistanceAxisChoice.MaxDistance: {
            if (_.isNil(disc.disc_model?.inbounds_flight_datum)) {
               return 210
            }

            return disc.max_distance
         }

         case M.SpeedDistanceAxisChoice.RimWidthRatio: {
            if (_.isNil(disc.disc_model?.pdga_approved_disc_model)) {
               return -0.9
            }

            return disc.disc_model.pdga_approved_disc_model?.rim_thickness_cm - disc.disc_model.pdga_approved_disc_model?.rim_depth_cm
         }

         default:
            throw new Error('Invalid Speed Distance Choice')
      }
   }

   private setupSVG() {
      if (!_.isNil(this.svg)) {
         this.container.innerHTML = ''
      }

      this.data_viewport_width = 375
      this.data_viewport_height = 525

      const max_fade = _.max(this.discs.map(x => x.fade + x.turn))
      const x_scale_range = [this.data_viewport_width, 0]
      switch (this.stability_axis_choice) {
         case M.StabilityAxisChoice.TurnFade: {
            this.x_scale = d3
               .scaleLinear()
               .domain([-5, _.max([5, max_fade])])
               .range(x_scale_range)
            break
         }

         case M.StabilityAxisChoice.HstLsf: {
            this.x_scale = d3.scaleLinear().domain([-100, 100]).range(x_scale_range)
            break
         }
      }

      const y_scale_range = [this.data_viewport_height, 0]
      switch (this.speed_distance_axis_choice) {
         case M.SpeedDistanceAxisChoice.Speed: {
            this.y_scale = d3.scaleLinear().domain([0, 15]).range(y_scale_range)
            break
         }

         case M.SpeedDistanceAxisChoice.MaxDistance: {
            this.y_scale = d3.scaleLinear().domain([200, 500]).range(y_scale_range)
            break
         }

         case M.SpeedDistanceAxisChoice.RimWidthRatio: {
            this.y_scale = d3.scaleLinear().domain([-1, 2]).range(y_scale_range)
            break
         }
      }

      this.offsets = this.calculateOffsets()

      this.svg = d3
         .select(this.container)
         .append('svg')
         .attr('viewBox', `0 0 ${this.offsets.total_width} ${this.offsets.total_height}`)
         .classed('max-h-screen', true)
         .classed('bg-white', true)
         .style(
            'font-family',
            `Inter var, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
         )

      const layer_requests = [
         {
            name: 'x_axis',
            root: this.svg,
            scrollable: false,
            x_offset: margin.padding_left,
            y_offset: this.offsets.data_viewport + margin.padding_top,
         },
         {
            name: 'y_axis',
            root: this.svg,
            scrollable: false,
            x_offset: margin.padding_left,
            y_offset: this.offsets.data_viewport + margin.padding_top,
         },
         {
            name: 'discs',
            root: this.svg,
            scrollable: false,
            x_offset: margin.padding_left + margin.left,
            y_offset: this.offsets.data_viewport + margin.padding_top,
         },
         { name: 'labels', root: this.svg, scrollable: false, x_offset: margin.padding_left, y_offset: margin.padding_top },
      ]

      const gfx_layers = _.map(layer_requests, layer => {
         const root_node = layer.root
         return {
            name: layer.name,
            layer: root_node.append('g').classed(layer.name, true).attr('transform', `translate(${layer.x_offset}, ${layer.y_offset})`),
            x_offset: layer.x_offset,
            y_offset: layer.y_offset,
            scrollable: layer.scrollable,
         }
      })

      this.layers = _.fromPairs(_.map(gfx_layers, x => [x.name, x.layer]))

      this.layers.discs.append('g').attr('class', 'disc-dots')

      // Prevent d3 from capturing of the scroll wheel
      // We want the user to be able to scroll down the page regardless of where
      // their cursor is.
      this.svg
         .on('mousewheel.zoom', null)
         .on('DOMMouseScroll.zoom', null) // disables older versions of Firefox
         .on('wheel.zoom', null) // disables newer versions of Firefox

      // Set-up the export button (rendered outside)
      d3.select('#save-image-button').on('click', () => {
         var svgString = getSVGString(this.svg.node(), 2 * this.data_viewport_width, 2 * this.data_viewport_height)

         svgString2Image(svgString, 2 * this.data_viewport_width, 2 * this.data_viewport_height, 'png', save) // passes Blob and filesize String to the callback

         function save(dataBlob, filesize) {
            saveAs(dataBlob, `discgolfbagbuilder-stability-chart.png`)
         }
      })
   }

   private renderXAxis() {
      const xAxis = g =>
         g
            .call(d3.axisTop(this.x_scale).tickSize(-this.data_viewport_height))
            .call(g => g.selectAll('.tick line').attr('stroke-opacity', 0.5).attr('stroke-dasharray', '2,2'))
            .call(g => g.selectAll('.tick text').attr('y', -7.5))

      let x_axis_container = this.layers.x_axis

      if (!x_axis_container.empty()) {
         x_axis_container.html('')
      }

      x_axis_container = this.layers.x_axis
         .append('g')
         .classed('text-gray-500', true)
         .classed('text-xs', true)
         .call(xAxis)
         .attr('transform', `translate(${margin.left}, 0)`)

      x_axis_container.selectAll('.domain').attr('opacity', 0)

      // text label for the x axis
      this.layers.x_axis
         .append('text')
         .classed('text-gray-500', true)
         .classed('text-xs', true)
         .attr('transform', `translate(${this.data_viewport_width / 2 + margin.left},${0 - margin.top + 10})`)
         .style('text-anchor', 'middle')
         .attr('fill', 'currentColor')
         .text(M.prettyStabilityAxisChoice(this.stability_axis_choice))
   }

   private renderYAxis() {
      const yAxis = g =>
         g
            .call(
               d3.axisRight(this.y_scale).ticks(15).tickSize(this.data_viewport_width)
               // .tickFormat(d => (this.measurement_unit === M.MeasurementUnit.Feet ? `${d} ft` : `${d} m`))
            )
            .call(g => g.selectAll('.tick line').attr('stroke-opacity', 0.5).attr('stroke-dasharray', '2,2'))
            .call(g =>
               g
                  .selectAll('.tick text')
                  .attr('x', -margin.left / 2)
                  .attr('dy', 3)
            )

      let y_axis_container = this.layers.y_axis

      if (!y_axis_container.empty()) {
         y_axis_container.html('')
      }

      y_axis_container = this.layers.y_axis
         .append('g')
         .classed('text-gray-500', true)
         .classed('text-xs', true)
         .call(yAxis)
         .attr('transform', `translate(${margin.left}, 0)`)

      y_axis_container.selectAll('.domain').attr('opacity', 0)

      // text label for the y axis
      this.layers.y_axis
         .append('text')
         .classed('text-gray-500', true)
         .classed('text-xs', true)
         .attr('transform', 'rotate(-90)')
         .attr('y', 0)
         .attr('x', 0 - this.data_viewport_height / 2)
         .attr('dy', '1em')
         .style('text-anchor', 'middle')
         .attr('fill', 'currentColor')
         .text(M.prettySpeedDistanceAxisChoice(this.speed_distance_axis_choice))
   }

   private renderDiscs() {
      if (_.isEmpty(this.discs)) {
         return
      }

      const scales = { x: this.x_scale, y: this.y_scale }

      const outer_this = this

      const renderDot = function (disc: M.Disc, index: number) {
         const selection = d3.select(this)

         const color = _.isEmpty(disc.color) ? outer_this.default_color_scale(index) : disc.color

         const color_is_too_bright = new TinyColor(color).getBrightness() > 220

         selection.classed(`disc-dot-group-${disc.disc_id}`, true)

         selection
            .append('circle')
            .classed(`disc-dot-${disc.disc_id}`, true)
            .attr('r', outer_this.dot_radius)
            .attr('fill', color)
            .attr('stroke', color_is_too_bright ? '#333' : null)
            .attr('stroke-width', color_is_too_bright ? '1px' : null)
            .attr('stroke-opacity', color_is_too_bright ? outer_this.stroke_opacity : null)

         if (outer_this.show_labels) {
            selection
               .append('text')
               .classed(`disc-label-${disc.disc_id}`, true)
               .classed(`text-xs text-gray-400 font-semibold`, true)
               .attr('text-anchor', 'middle')
               .attr('dy', '-1.1em')
               .text(disc.disc_model?.name || disc.disc_model_manual)
         }

         // const outline_path = color_is_too_bright
         //    ? selection
         //         .append('path')
         //         .attr('transform', 'translate(1, 0)')
         //         .attr('stroke-width', stroke_width - 1)
         //         .attr('d', line(path_data.xy_pairs))
         //         .attr('stroke', '#333')
         //         .attr('opacity', 0.5)
         //         .attr('fill', 'none')
         //    : null

         // const outline_path_2 = color_is_too_bright
         //    ? selection
         //         .append('path')
         //         .attr('transform', 'translate(-1, 0)')
         //         .attr('stroke-width', stroke_width - 1)
         //         .attr('d', line(path_data.xy_pairs))
         //         .attr('stroke', '#333')
         //         .attr('opacity', 0.5)
         //         .attr('fill', 'none')
         //    : null

         // const path = selection
         //    .append('path')
         //    .attr('stroke-width', stroke_width)
         //    .attr('d', line(path_data.xy_pairs))
         //    .attr('stroke', _.isEmpty(disc.color) ? my_color(index) : disc.color)
         //    .attr('opacity', stroke_opacity)
         //    .attr('fill', 'none')
      }
      const attribution_selection = this.layers.labels
         .append('g')
         .attr('opacity', 0.7)
         .attr(
            'transform',
            `translate(${this.data_viewport_width + margin.left - 160}, ${this.data_viewport_height + margin.top - margin.padding_top})`
         )
      attribution_selection.append('rect').attr('width', 160).attr('height', 25).attr('fill', 'white').attr('y', '-20')

      attribution_selection.append('text').attr('class', 'site-attribution text-gray-400 text-sm bg-white').text('discgolfbagbuilder.com')

      const selection = this.layers.discs.select('.disc-dots').selectAll('.disc-dot').data(this.discs)

      selection
         .enter()
         .append('g')
         .attr('transform', d => `translate(${scales.x(this.getXAttribute(d))}, ${scales.y(this.getYAttribute(d))})`)
         .attr('class', 'disc-dot')
         .each(renderDot)
         .on('mouseover', this.showTooltip(this))
         .on('mouseout', this.removeTooltip)
         .on('click', disc => {
            // TODO: track event in ahoy : window._gaq.push(['trackEvent', 'adherence-chart', 'dose-event-click', period_date, null])
            this.onDiscSelected(disc)
         })

      selection.exit().remove()
   }

   //Show the tooltip on the hovered over slice
   private showTooltip(ctx) {
      return function (disc: M.Disc, index: number) {
         // var d3_element = d3.selectAll(`.disc-dot-${d.disc.disc_id}`)
         // const element = d3_element.node() as Element
         // const stability = ctx.getXAttribute(disc)
         // const bands = ctx.getXAttributeBands(disc)
         // const origin = ctx.x_scale(stability)

         // ctx.layers.discs
         //    .select(`.disc-dot-group-${disc.disc_id}`)
         //    .selectAll(`disc-band-${disc.disc_id}`)
         //    .enter()
         //    .append('line')
         //    .classed(`disc-band-${disc.disc_id}`, true)
         //    .attr('x1', ctx.x_scale(stability - bands.left) - origin)
         //    .attr('x2', ctx.x_scale(bands.right) - origin)
         //    .attr('stroke', _.isEmpty(disc.color) ? ctx.default_color_scale(index) : disc.color)
         //    .attr('stroke-width', 2)
         //    .attr('stroke-opacity', ctx.stroke_opacity)

         const tooltipContent = () => {
            return `
         <div class="rounded-md shadow-lg">
            <div class="rounded-md bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
               <div class="px-4 py-3">
               <span class="text-md text-gray-800">${disc.disc_model?.disc_manufacturer?.common_name || disc.disc_manufacturer_manual} ${
               disc.plastic_name ?? ''
            } ${disc.disc_model?.name || disc.disc_model_manual}</span>
               </div>
            </div>
         </div>
         `
         }

         tippy(`.disc-dot-${disc.disc_id}`, {
            content: tooltipContent(),
            allowHTML: true,
         })
      }
   }

   private removeTooltip(disc: M.Disc, i) {
      var d3_element = d3.selectAll(`.disc-dot-${disc.disc_id}`)
      const element: any = d3_element.node() as Element

      if (element) {
         element._tippy?.destroy()
      }
   }

   private calculateOffsets() {
      const data_viewport = margin.top
      const x_axis = data_viewport + this.data_viewport_height

      return {
         data_viewport: data_viewport,
         x_axis: x_axis,
         total_height: this.data_viewport_height + margin.top + margin.bottom + margin.padding_top,
         total_width: this.data_viewport_width + margin.left + margin.right + margin.padding_left,
      }
   }

   setMeasurementUnit(measurement_unit: M.MeasurementUnit) {
      this.measurement_unit = measurement_unit

      if (!_.isEmpty(this.discs)) {
         this.renderAll(true)
      }
   }

   setSpeedDistanceAxisChoice(speed_distance_axis_choice: M.SpeedDistanceAxisChoice) {
      this.speed_distance_axis_choice = speed_distance_axis_choice

      if (!_.isEmpty(this.discs)) {
         this.renderAll(true)
      }
   }

   setStabilityAxisChoice(stability_axis_choice: M.StabilityAxisChoice) {
      this.stability_axis_choice = stability_axis_choice

      if (!_.isEmpty(this.discs)) {
         this.renderAll(true)
      }
   }

   setShowLabels(show_labels: boolean) {
      this.show_labels = show_labels

      if (!_.isEmpty(this.discs)) {
         this.renderAll(true)
      }
   }
}
