import { FlightPathOrientation } from 'components/flight-chart/helper'

export interface Bag {
   bag_id: number
   slug: string
   user_id: number
   name: string
   description: null
   notes: null
   bag_style: null
   bag_color: null
   public: boolean
   preview_url: null
   youtube_video_id: string
   season_year: number
   primary_sponsor: string
   created_at: Date
   updated_at: Date
   is_featured: boolean
   user: User
   discs: Disc[]
}

export interface Disc {
   disc_id: number
   disc_key: string
   user_id: number
   name: string
   disc_model_id: number
   weight: null
   plastic_name: string
   color: string
   stamp: string
   ink: boolean
   condition: number
   picture_url: null
   notes: string
   disc_manufacturer_manual: string
   disc_model_manual: string
   speed: number | null
   glide: number | null
   turn: number | null
   fade: number | null
   max_distance: number | null
   hst: number | null
   lsf: number | null
   turn_adjustment: number | null
   fade_adjustment: number | null
   throwing_handedness: FlightPathOrientation | null
   created_at: Date
   updated_at: Date
   disc_model: DiscModel
}

export interface DiscModel {
   disc_model_id: number
   slug: string
   disc_manufacturer_id: number
   pdga_approved_disc_model_id: number
   inbounds_flight_data_id: number
   infinite_discs_disc_model_id: number
   name: string
   disc_type: DiscType
   is_prototype: boolean
   picture_url: null
   speed: number
   glide: number
   turn: number
   fade: number
   created_at: Date
   updated_at: Date
   inbounds_flight_datum: InboundsFlightDatum | null
   pdga_approved_disc_model: PdgaApprovedDiscModel
   disc_manufacturer: DiscManufacturer
}

export interface DiscManufacturer {
   disc_manufacturer_id: number
   disc_manufacturer_key: string
   pdga_official_name: string
   common_name: string
   infinite_common_name: string
   description: null
   manufacturer_website_link: null
   infinite_discs_website_link: string
   created_at: Date
   updated_at: Date
}
export enum DiscType {
   DistanceDriver = 'distance-driver',
   FairwayDriver = 'fairway-driver',
   Midrange = 'midrange',
   PuttApproach = 'putt-approach',
}

export enum MeasurementUnit {
   Feet = 'feet',
   Meters = 'meters',
}

export enum PowerLevel {
   Novice = 'novice',
   Recreational = 'recreational',
   Intermediate = 'intermediate',
   Advanced = 'advanced',
   Pro = 'pro',
   ElitePro = 'elite-pro',
}

export interface InboundsFlightDatum {
   inbounds_flight_data_id: number
   inbounds_flight_data_key: string
   max_distance: number
   hst: number
   lsf: number
   net: number
   created_at: Date
   updated_at: Date
}

export interface PdgaApprovedDiscModel {
   pdga_approved_disc_model_id: number
   pdga_approved_disc_model_key: string
   disc_manufacturer_id: number
   name: string
   max_weight_grams: number
   diameter_cm: number
   height_cm: number
   rim_depth_cm: number
   inside_rim_diameter_cm: number
   rim_thickness_cm: number
   rim_depth_diameter_ratio: number
   rim_configuration: number
   flexibility_kg: number
   pdga_disc_class: null
   vintage_max_weight_grams: null
   certification_number: string
   approved_on: Date
   created_at: Date
   updated_at: Date
}

export interface User {
   user_id: number
   admin: boolean
   name: string
   username: string
   email: string
   key: null
   pdga_number: string
   pdga_class: null
   city: string
   state: string
   country: string
   created_at: Date
   updated_at: Date
}

export interface ChartExtents {
   container_width: number
}

export interface LoadedChartData {
   discs: Disc[]
}

export const Empty: Empty = {}
export type Empty = {}

export const Loading: Loading = {}
export type Loading = {}

export type ChartData = Empty | Loading | LoadedChartData

export function prettyPowerLevel(power_level: PowerLevel) {
   switch (power_level) {
      case PowerLevel.Novice:
         return 'Novice'
      case PowerLevel.Recreational:
         return 'Recreational'
      case PowerLevel.Intermediate:
         return 'Intermediate'
      case PowerLevel.Advanced:
         return 'Advanced'
      case PowerLevel.Pro:
         return 'Pro'
      case PowerLevel.ElitePro:
         return 'Elite Pro'
      default:
         return 'Unknown'
   }
}

export enum SpeedDistanceAxisChoice {
   RimWidthRatio = 'rim-width-ratio',
   Speed = 'speed',
   MaxDistance = 'max-distance',
}

export enum StabilityAxisChoice {
   TurnFade = 'turn-fade',
   HstLsf = 'hst-lsf',
}

export function prettyStabilityAxisChoice(axis_choice: StabilityAxisChoice) {
   switch (axis_choice) {
      case StabilityAxisChoice.TurnFade:
         return 'Turn + Fade'
      case StabilityAxisChoice.HstLsf:
         return 'Net Stability (HST + LSF)'
      default:
         return 'Unknown'
   }
}

export function prettySpeedDistanceAxisChoice(axis_choice: SpeedDistanceAxisChoice) {
   switch (axis_choice) {
      case SpeedDistanceAxisChoice.RimWidthRatio:
         return 'Rim Width Ratio'
      case SpeedDistanceAxisChoice.Speed:
         return 'Speed'
      case SpeedDistanceAxisChoice.MaxDistance:
         return 'Max Distance'
      default:
         return 'Unknown'
   }
}
